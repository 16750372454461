.darkBG {
    /* background-color: rgba(0, 0, 0, 0.2); */
    width: 100vw;
    height: 100vh;
    z-index: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }
  
  .centered {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .modal {
    max-height: calc(100vh - 210px);
    overflow-y: auto;
    background: rgb(253, 204, 204);
    color: white;
    z-index: 10;
    border-radius: 16px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  }
  
  .modalHeader {
    height: 50px;
    /* background: white; */
    overflow: hidden;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }
  
  .heading {
    margin: 0;
    padding: 10px;
    color: #2c3e50;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
  }
  
  .subHeading {
    color: #2c3e50;
    font-weight: 700;
    font-size: 14px;
    text-align: center;
    
  }

  .subHeading2 {
    color: #2c3e50;
    font-weight: 700;
    font-size: 14px;
    text-align: center;
    padding-left: 200px;
  }

  .modalContent {
    display: flex;
    flex-wrap: wrap;
  }
  
  .modalContent img{
    opacity: 0.6;
  }

  .modalContent img:hover{
    opacity: 1;
  }
 