body {
  margin: 0;
  font-family: sans-serif;
  /* background: #f0f0f0; */
}

.flexContainer {
  display: flex;
  flex: 1 1 0;
  justify-content: space-around; 
  /* background-color: rgb(223, 244, 232); */
  padding-top: 20px;
}

svg .game {
  transform: rotate(-90deg);
}

svg .game g {
  /* fill: #02571a; */
  fill-opacity: 0.6;
}

.blocked {
  fill: #02571a;
}

.blocked:hover {
  fill-opacity: 1;
}

svg .game g:hover {
  fill-opacity: 1;
  cursor: pointer;
}
svg .game g:hover text {
  fill-opacity: 1;
  cursor: pointer;
}

svg .game g polygon {
  stroke: #02571a;
  stroke-width: 0.2;
  transition: fill-opacity .2s;
}
svg .game g text {
  transform: rotate(90deg);
  font-size: 0.2em;
  fill: #000000;
  fill-opacity: 1;
  transition: fill-opacity .2s;
}
svg .game path {
  fill: none;
  stroke: hsl(60, 20%, 70%);
  stroke-width: 0.4em;
  stroke-opacity: 0.3;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.table {
  border: 1px solid #126d70;
  border-radius: 5px;
}


.hex-0 {
  fill: #d1d7f8;
}

.hex-1 {
  fill: #b7c3f8;
}

.hex-2 {
  fill: #7e95fa;
}

.hex-3 {
  fill: #5876fa;
}

.hex-4 {
  fill: #3c5ef8;
}

.hex-5 {
  fill: #022df5;
}

.hex-6 {
  fill: #021570;
}

.hex-S {
  fill: #f6a64a;
}