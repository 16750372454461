
.History {
    width: 90%;
    margin: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .History table{
    width:100%;
    table-layout: fixed;
    border: 1px solid #7c7c7c;
  }

  .History th{
    padding: 10px 10px;
    text-align: center;
    font-weight: 600;
    font-size: 12px;
    color: #145a32;
    text-transform: uppercase;
  }
  .History td{
    padding-top: 10px;
    text-align: center;
    vertical-align:middle;
    font-weight: 400;
    font-size: 14px;
    color:#000000;
    border-bottom: solid 1px rgba(255,255,255,0.1);
  }