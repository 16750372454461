
.Rewards {
    /* width: 95%; */
    margin: 30px 10px;
}

.Rewards table{
    width: 100%;
    table-layout: fixed;
    border: 1px solid #7c7c7c;
}

.Rewards th{
    text-align: left;
    font-weight: 600;
    font-size: 12px;
    color: #145a32;
    text-transform: uppercase;
}

.Rewards tr {
    text-transform: none;
}

.Rewards td{
    padding-top: 5px;
    font-weight: 400;
    font-size: 15px;
    color:rgb(16, 15, 15);
}


.Rewards i {
  background-color: #c5ebd5;
  padding: 0px 3px;
  border-radius: 10px;
}
.headingInfo {
    padding-left: 15px;
}